
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import Login from "views/pages/Login.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";
import CategoryIndex from "./views/admin/categories/CategoryIndex";
import ServiceIndex from "./views/admin/services/ServiceIndex";
import UserIndex from "./views/admin/users/UserIndex";
import ShowUser from "./views/admin/users/ShowUser";
import BillIndex from "./views/admin/bills/BillIndex";
import ServiceUserIndex from "./views/users/services/ServiceUserIndex";
import OrderUserIndex from "./views/users/orders/OrderUserIndex";
import BillUserIndex from "./views/users/bills/BillUserIndex";
import OrderIndex from "./views/admin/orders/OrderIndex";
import OrderHistory from "./views/admin/orders/OrderHistory";
import UserServices from "./views/admin/users/UserServices";
import AdminUserOrders from "./views/admin/users/AdminUserOrders";
import DiegoIndex from "./views/diego/DiegoIndex";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
    admin: true
  },
  {
    collapse: true,
    name: "Admin",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    admin:true,
    views: [
      {
        path: "/admin-categories",
        name: "Categories",
        mini: "C",
        component: CategoryIndex,
        layout: "/admin",
      },
      {
        path: "/admin-services",
        name: "Services",
        mini: "S",
        component: ServiceIndex,
        layout: "/admin",
      },

      {
        path: "/admin-users",
        name: "Users",
        mini: "U",
        component: UserIndex,
        layout: "/admin",
      },
      {
        path: "/admin-bills",
        name: "Bills",
        mini: "B",
        component: BillIndex,
        layout: "/admin",
      },

    ],
  },
  {
    collapse: true,
    name: "Orders Admin",
    icon: "nc-icon nc-book-bookmark",
    state: "orders",
    admin:true,
    views: [

      {
        path: "/admin-orders",
        name: "Orders Pending",
        mini: "O",
        component: OrderIndex,
        layout: "/admin",
      },
      {
        path: "/admin-order-history",
        name: "Order History",
        mini: "B",
        component: OrderHistory,
        layout: "/admin",
      },

    ],
  },
  {
    path: "/services",
    name: "Services",
    icon: "nc-icon nc-box",
    mini: "S",
    component: ServiceUserIndex,
    layout: "/admin",
  },
  {
    icon: "nc-icon nc-box",
    layout: "/admin",
    redirect: true,
    path: "/admin-user-services/:id",
    name: "User Admin Services",
    mini: "S",
    component: UserServices,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "nc-icon nc-box",
    mini: "O",
    component: OrderUserIndex,
    layout: "/admin",
  },
  {
    path: "/bills",
    name: "Bills",
    icon: "nc-icon nc-box",
    mini: "B",
    component: BillUserIndex,
    layout: "/admin",
  },
  {
    icon: "nc-icon nc-box",
    path: "/admin-user/:id",
    name: "User",
    component: ShowUser,
    layout: "/admin",
    redirect: true
  },
  {
    icon: "nc-icon nc-box",
    path: "/admin-user-bills/:id",
    name: "User",
    component: ShowUser,
    layout: "/admin",
    redirect: true
  },
  {
    icon: "nc-icon nc-box",
    path: "/admin-user-orders/:id",
    name: "User",
    component: AdminUserOrders,
    layout: "/admin",
    redirect: true
  },
  {
    collapse: true,
    name: "Pages",
    redirect: true,
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    redirect: true,
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    redirect: true,
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    redirect: true,
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    redirect: true,
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    redirect: true,
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    redirect: true,
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    redirect: true,
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin",
  },
  {
    path: "/diego",
    name: "Diego Orders",
    //redirect: false,
    icon: "nc-icon nc-calendar-60",
    component: DiegoIndex,
    layout: "/admin",
    diego: true,
  },
];

export default routes;
