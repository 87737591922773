import React, {Component} from 'react';
import {Button, Input, Modal} from "reactstrap";
import ServiceFunctions from "./ServiceFunctions";
import Select from "react-select";

class UpdateServiceModal extends Component {

  constructor(props) {
    super(props);
    this.state={
      name:"",
      price:'',
      category:[]
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value});

  onSubmit = () => {
    const {name, price} = this.state;
    const category_id = this.state.category.value;
   // console.log(this.state)
    const data = {name, price, category_id};
    new ServiceFunctions().update(this.props.service.id, data, this.props.error, this.props.success);
    this.props.toggle(this.props.service)
  }

  componentDidMount() {
    let category = this.props.service.category;
    this.setState({})
  }


  render() {
    const {name, price, category} = this.props.service;
    return (
      <div>
        <Modal
          className="modal-sm"
          modalClassName="modal-primary"
          onOpened={() => this.setState({name, price, category: {label: category.name, value: category.id}})}
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header justify-content-center">
            <div className=" ml-auto mr-auto">
              <p>{`Update service ${this.state.service && this.state.service.name}`}</p>
            </div>
          </div>
          <div className="modal-body">
            <p>
              <Input
                placeholder={'Name'}
                value={this.state.name}
                onChange={this.onChange}
                name={'name'}
              />
            </p>
            <p>
              <Input
                placeholder={'Price'}
                value={this.state.price}
                onChange={this.onChange}
                name={'price'}
              />
            </p>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="category_id"
              value={this.state.category}
              onChange={(value) => this.setState({category: value})}
              options={
                this.props.categories.map(category => {
                  return {
                    value: category.id, label: category.name
                  }
                })
              }
              placeholder="Category"
            />

          </div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                color="link"
                type="button"
                onClick={() => this.onSubmit()}
              >
                Save
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggle(this.props.service)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default UpdateServiceModal;