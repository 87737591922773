import React, {Component} from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import axios from 'axios';

import UserFunctions from "./UserFunctions";
import CreateUserModal from "./CreateUserModal";
import store from "../../../store";
import Loading from "../../../components/Loading";
import cookie from "js-cookie";
import {paginate} from "../../../functions/paginate";
import UpdatePriceModal from "./UpdatePriceModal";

class UserServices extends Component {

  constructor(props) {
    super(props);
    this.state={
      user: props.location.state,
      userServices:props.services,
      updateModal: false,
      service:[],
      isLoading: false,
    }
   // console.log(props)
  }

  componentDidMount() {
    this.getServices();
  }

  getServices = () => new UserFunctions().getUserServices(this.state.user.id, (data) => this.successCallback(data));

  updateService = service => {
    if (!service){
      service = []
    }
    this.setState({updateModal: !this.state.updateModal, service});
  }

  paginate = async url => {
    this.setState({isLoading: true});
    await paginate(url, "SET_USER_SERVICES")
    this.setState({isLoading: false});
  }

  label = label => {
    if (label === "&laquo; Previous"){
      label = "<  Previous";
    } else if  (label === "Next &raquo;"){
      label = "Next  >";
    }
    return label;
  }

  changePrice = (data) => {
    new UserFunctions().changePrice(data.id, data);
  }

  successCallback = (data) => {
    //console.log(data)
  }

  render() {
    const {userServices} = this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Services</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr className={'text-center'}>
                    <th>ID</th>
                    <th>Service</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    userServices.data.map(service => {
                      return (
                        <tr className={'text-center'} key={`user-services-${service.id}`}>
                          <td>{service.id}</td>
                          <td>{service.service.name}</td>
                          <td>{service.price}</td>
                          <td>
                            <Button
                              size={'sm'}
                              color={'primary'}
                              className={'btn-round'}
                              onClick={() => this.updateService(service)}
                            >
                              Change Price
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  userServices.links.map(link => {
                    return (
                      <Button
                        size={'sm'}
                        disabled={!link.active}
                        className={'mx-1 btn-round'}
                        color={'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {this.label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
              <UpdatePriceModal
                isOpen={this.state.updateModal}
                toggle={data => this.updateService(data)}
                service={this.state.service}
                onChangePrice={(data) => this.changePrice(data)}
              />
              <Loading isLoading={this.state.isLoading} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userServices: state.userServices.userServices
  }
}

export default connect(mapStateToProps) (UserServices);