import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import LoginFunctions from "./functions/LoginFunctions";
import Loading from "../../components/Loading";
import cookie from "js-cookie";

class Login extends React.Component {

  constructor() {
    super();
    this.state={
      email:'',
      password:'',
      isLoading:false,
      error:''
    }
  }

  onChangeHandler = (e) => this.setState({[e.target.name]: e.target.value, error:''});

  onSubmit = async (e) => {
    this.setState({isLoading: true, error:''});
    const {email, password} = this.state;
    const data = {email, password};
    this.login(data);
  }

  login =  (data) => {
    new LoginFunctions().login(data, (error) => this.errorResponse(error));
  }

  errorResponse = (data) => {
    this.setState({isLoading: false, error: data});
  }

  componentDidMount() {
    let token = cookie.get("token");
    if (token) {
      new LoginFunctions().getProfile((error) => this.errorResponse(error));
    }
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  render() {
    return (
        <div className="login-page">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form action="" className="form" method="" onSubmit={e => e.preventDefault(this.onSubmit)}>
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Login</h3>
                        <h6 className="header text-center text-danger">{this.state.error}</h6>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Email..."
                            type="text"
                            name={"email"}
                            value={this.state.email}
                            onChange={this.onChangeHandler}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            name={"password"}
                            value={this.state.password}
                            onChange={this.onChangeHandler}
                        />
                      </InputGroup>
                      <br/>
                    </CardBody>
                    <CardFooter>
                      <Button
                          type={'submit'}
                          block
                          className="btn-round"
                          color="info"
                          onClick={() => this.onSubmit()}
                      >
                        {
                          this.state.isLoading ? <Spinner type="grow" size={'sm'} className="text-center text-secundary" />
                              : 'Get Started'
                        }
                      </Button>
                    </CardFooter>
                    <Loading isLoading={this.state.isLoading} />
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${
                    require("assets/img/bg/ana-bernardo.jpg").default
                })`,
              }}
          />
        </div>
    );
  }
}

export default Login;
