
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Input, Table,
} from "reactstrap";

import axios from "axios";

import cookie from "js-cookie";
import DashboardCharts from "./dashboardCharts";
import {api} from "../functions/Base";

class Dashboard extends React.Component {
  constructor() {
    console.log('constructor');
    super();
    this.state={
      usersCount:0,
      orderSuccess:0,
      orders:0,
      monthFree:[],
      monthPremium:[],
      monthIphone:[],
      digo:[],
      checks:[],
      mounthBlacklist: [],
    }
  }

  onchange = (e, data) => {
    this.setState({[data]: e.format()});
  }

  componentDidMount() {
    this.getInitialData()
  }

  getInitialData = async () => {
    await axios.get(`${api}/admin_dashboard`, {
      headers:{
        Authorization: `Bearer ${cookie.get("token")}`,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(res => {
      console.log(res);
      const {mounthBlacklist, monthIphone,monthPremium, usersCount, monthFree,  orderSuccess, orders, checks} = res.data.data
      this.setState({ mounthBlacklist, monthIphone, monthPremium, usersCount, orderSuccess, monthFree, orders, checks})
    }).catch(err => {
      console.log(err.response);
    })
  }

  render() {
    const {usersCount, orderSuccess, orders, mounthBlacklist} = this.state;
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning"/>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Users</p>
                        <CardTitle tag="p">{usersCount}</CardTitle>
                        <p/>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr/>
                  <div className="stats">
                    <i className="fa fa-calendar-o"/>
                    Total Registered
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-success"/>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Orders Complete</p>
                        <CardTitle tag="p">{orderSuccess}</CardTitle>
                        <p/>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr/>
                  <div className="stats">
                    <i className="fa fa-calendar-o"/>
                    In the last month
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-vector text-danger"/>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Orders</p>
                        <CardTitle tag="p">{orders}</CardTitle>
                        <p/>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr/>
                  <div className="stats">
                    <i className="fa fa-calendar-o"/>
                    In the last month
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
           <Row>
             <Col>
              <DashboardCharts  title={'TOTAL SUCCESS CLEAN ORDERS IN LAST 30 DAYS'} days={this.state.monthFree} />
             </Col>
           </Row>
          <Row>
            <Col>
              <DashboardCharts  title={'TOTAL SUCCESS BLACKLIST ORDERS IN LAST 30 DAYS'} days={mounthBlacklist} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DashboardCharts  title={'TOTAL SUCCESS CLEAN ORDERS IN LAST 30 DAYS'} days={this.state.monthPremium} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DashboardCharts  title={'TOTAL IPHONES UNLOCKED IN 30 DAYS'} days={this.state.monthIphone} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DashboardCharts  title={'Checks'} days={this.state.checks} />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
