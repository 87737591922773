import Base from "../../../functions/Base";
import store from "../../../store";
export default class CategoryFunctions extends Base {
  get(data, errorResponse){
    this.instance.get('/categories', data).then(res => {
      store.dispatch({
        type: "SET_CATEGORIES",
        payload: res.data.data
      });
    }).catch(err => {
      console.log(err.response)
      err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
    });
  }

  store (data, errorResponse, successResponse){
    this.instance.post('/categories', data).then(res => {
      store.dispatch({
        type: "SET_CATEGORIES",
        payload: res.data.data
      });
      successResponse(res.data.data)
    }).catch(err => {
      console.log(err.response)
      const  errors={
        message: "Error Network..."
      }
      err.response ? errorResponse(err.response.data) : errorResponse(errors);
    })
  }

  update (id, data, errorResponse){
    this.instance.patch(`/categories/${id}`, data).then(res => {
       store.dispatch({
         type: "SET_CATEGORIES",
         payload: res.data.data
       });
    }).catch(err => {
      console.log(err.response)
      const  errors={
        message: "Error Network..."
      }
      err.response ? errorResponse(err.response.data) : errorResponse(errors);
    })
  }

  delete (id){
    this.instance.delete(`categories/${id}`).then(res => {
      store.dispatch({
        type: "SET_CATEGORIES",
        payload: res.data.data
      });
    })
  }
}