import Base from "../../../functions/Base";
import store from "../../../store";
export default class BillFunctions extends Base {
  get(data, errorResponse){
    this.instance.get('/admin_bills').then(res => {
      store.dispatch({
        type: "SET_BILLS",
        payload: res.data.data
      });
    }).catch(err => {
      //console.log(err.response)
      err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
    });
  }
}