import React, {Component} from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class ResponseModal extends Component {
  constructor() {
    super();
    this.state={
      code:''
    }
  }

  open = () => {
    const {order} = this.props;
    if (order.service_id === 6) {
      if (this.state.code === "" || this.state.code !== "Unlocked") {
        this.setState({
          code: 'Unlocked'
        });
      }
    } else {
      if (this.state.code === "" || this.state.code !== "Blocked for Stolen. Allow 72 hours until it shows in GSMA Blacklist database.") {
        this.setState({
          code: 'Blocked for Stolen. Allow 72 hours until it shows in GSMA Blacklist database.'
        });
      }
    }
  }

  onChange = e => {
    console.log("name", e.target.name, "value", e.target.value)
    this.setState({[e.target.name]: e.target.value})
  };

  onsubmit = () => {
    this.props.response({order: this.props.order, code:this.state.code});
    this.setState({code:''})
  }

  render() {
    const {isOpen, toggle, order} = this.props
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onOpened={this.open}
      >
        <ModalHeader>
          Response Order
        </ModalHeader>
        <ModalBody>
          <label>Code for IMEI: {order.imei}</label>
          <Input
            disabled={order.service_id === 20 || order.service_id === 6}
            type={'textarea'}
            value={this.state.code}
            name={'code'}
            onChange={this.onChange}
            placeholder={'Code'}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color={'danger'}
            size={'sm'}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            color={'success'}
            size={'sm'}
            onClick={() => this.onsubmit()}
          >
            Send Success
          </Button>
        </ModalFooter>

      </Modal>
    );
  }
}

export default ResponseModal;
