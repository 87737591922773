import React, {Component} from 'react';
import UserFunctions from "./UserFunctions";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row} from "reactstrap";

class ShowUser extends Component {
  constructor(props) {
    super(props);
    this.state={
      user: props.location.state,
      credits:'',
      name:props.location.state.name,
      email:props.location.state.email,
      password:'',
      c_password:'',
      errors:[]
    }
  }

  componentDidMount() {
    new UserFunctions().show(this.props.match.params.id, data => this.successCallback(data))
  }

  onChange = e => this.setState({[e.target.name]: e.target.value, errors:[]});

  onSubmit = (data) => {
    if (!data) {
      data = {api_key: true}
    }
    this.setState({password:'', c_password:'', errors:[]})
    new UserFunctions().update(
      this.state.user.id,
      data,
        data => this.successCallback(data),
      data => this.errorCallback(data)
    );
  }

  successCallback = ({data}) => {
   // console.log(data)
    this.setState({user: data.data, credits: ''});

  }

  errorCallback = data => this.setState({errors:data})

  render() {
    const {user, credits, name, email, password, c_password, errors} = this.state;
    return (
      <div className="content">
        <Row>
          <Col md="6">
            <Card>
              <p className="text-danger">{errors.data && errors.message && errors.message}</p>
              <CardHeader>
                <CardTitle tag="h4">Change Api Key</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" method="#">
                  <label>Api Key</label>
                  <FormGroup>
                    <Input  type="text" value={user.api_key} disabled />
                  </FormGroup>
                  <Button
                    color={'primary'}
                    className={'rounded'}
                    size={'sm'}
                    block
                    onClick={() => this.onSubmit()}
                  >
                    Change Api Key
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add Credits</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" method="#">
                  <label>Credits {user.credits}</label>
                  <FormGroup>
                    <Input
                      type="text"
                      value={credits}
                      name={'credits'}
                      placeholder={'Credits'}
                      onChange={this.onChange}
                    />
                    <h6 color={'danger'}>{errors.data && errors.data.credits && errors.data.credits[0]}</h6>

                  </FormGroup>
                  <Button
                      color={'primary'}
                      className={'rounded'}
                      size={'sm'}
                      block
                      disabled={!this.state.credits}
                      onClick={() => this.onSubmit({credits: this.state.credits})}
                  >
                    Add Credits
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Change credentials</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" method="#">
                  <label>Name</label>
                  <FormGroup>
                    <Input
                      type="text"
                      value={name}
                      placeholder={'Name'}
                      name={'name'}
                      onChange={this.onChange}
                    />
                    <p className="text-danger">{errors.data && errors.data.name && errors.data.name[0]}</p>
                  </FormGroup>
                  <label>Email</label>
                  <FormGroup>
                    <Input
                      type="text"
                      value={email}
                      placeholder={'Email'}
                      name={'email'}
                      onChange={this.onChange}
                    /><p className="text-danger">{errors.data && errors.data.email && errors.data.email[0]}</p>
                  </FormGroup>
                  <Button
                    color={'primary'}
                    className={'rounded'}
                    size={'sm'}
                    block
                    onClick={() => this.onSubmit({name, email})}
                  >
                    Change Name and Email
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Change Password</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" method="#">
                  <label>Password</label>
                  <FormGroup>
                    <Input
                      type="text"
                      value={this.state.password}
                      name={'password'}
                      placeholder={'Password'}
                      onChange={this.onChange}
                    />
                    <p className="text-danger">{errors.data && errors.data.password && errors.data.password[0]}</p>
                  </FormGroup>
                  <label>Confirm Password</label>
                  <FormGroup>
                    <Input
                      type="text"
                      value={this.state.c_password}
                      name={'c_password'}
                      placeholder={'Confirm Password'}
                      onChange={this.onChange}
                    />
                    <p className="text-danger">{errors.data && errors.data.c_password && errors.data.c_password[0]}</p>
                  </FormGroup>
                  <Button
                    color={'primary'}
                    className={'rounded'}
                    size={'sm'}
                    block
                    onClick={() => this.onSubmit({password, c_password})}
                  >
                    Change Password
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Order History</CardTitle>
                <p className="text-danger">
                  {
                    this.state.user.orders &&
                    !this.state.user.orders.length &&
                    "This user do not have any order"
                  }
                </p>
              </CardHeader>
              <CardBody>
                <Button
                  color={'primary'}
                  className={'rounded'}
                  size={'sm'}
                  block
                  disabled={this.state.user.orders && !this.state.user.orders.length}
                  onClick={() => this.props.history.push(`/admin/admin-user-orders/${user.id}`, user)}
                >
                  Check order history
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Bills History</CardTitle>
                <p className="text-danger">
                  {
                    this.state.user.bills &&
                    !this.state.user.bills.length &&
                    "This user do not have any bill"
                  }
                </p>
              </CardHeader>
              <CardBody>
                <Button
                  color={'primary'}
                  className={'rounded'}
                  size={'sm'}
                  block
                  disabled={this.state.user.bills && !this.state.user.bills.length}
                  onClick={e => e.preventDefault()}
                >
                  Check bill history
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Services Price</CardTitle>
              </CardHeader>
              <CardBody>
                <Button
                  color={'primary'}
                  className={'rounded'}
                  size={'sm'}
                  block
                  onClick={() => this.props.history.push(`/admin/admin-user-services/${user.id}`, user)}
                >
                  Check prices for this user
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default  ShowUser;