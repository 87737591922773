import Base from "../../functions/Base";
import store from "../../store";
export default class DiegoFunctions extends Base {
    get(data, errorResponse){
        this.instance.get('/diego-orders').then(res => {
            store.dispatch({
                type: "SET_ORDERS_PENDING",
                payload: res.data.data
            });
        }).catch(err => {
            console.log(err.response)
            err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
        });
    }
    update (id, data, successResponse){
        this.instance.patch(`/diego-orders/${id}`, data).then(res => {
            store.dispatch({
                type: "SET_ORDERS_PENDING",
                payload: res.data.data
            });
            successResponse()
        }).catch(err => {
            console.log(err.response)
            successResponse()
        })
    }

    history(){
        this.instance.get('admin_orders_history').then(res => {
            store.dispatch({
                payload: res.data.data,
                type:"SET_ORDERS_HISTORY"
            })
        }).catch(err => {
            console.log(err);
        })
    }

    filter(data) {
        this.instance.get(`admin_filter_orders?imei=${data.imei}&status=${data.status}&service_id=${data.service_id}`).then(res => {
            store.dispatch({
                type: "SET_ORDERS_HISTORY",
                payload: res.data.data
            });
        })
    }
}
