import React, {Component} from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input, Label,
  Row,
  Table
} from "reactstrap";
import {connect} from "react-redux";
import OrderUserFunctions from "./OrderUserFunctions";
import moment from "moment";
import {label, paginate} from "../../../functions/paginate";
import ReactHtmlParser from 'react-html-parser';

class OrderUserIndex extends Component {
  constructor(props) {
    super(props);
    this.state={
      order_user:props.order_user,
      imei: '',
      status: '',
      service_id: '',
      filter: false,
    }
  }

  componentDidMount() {
    new OrderUserFunctions().get()
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.onSubmit();
    })
  }

  onSubmit = () => {
    this.setState({filter: true});
    const {imei, status, service_id} = this.state;
    const data = {imei, status, service_id};
    new OrderUserFunctions().filter(data);
  }

  paginate = async url => {
    this.setState({isLoading: true});
    const {imei, status, service_id} = this.state;
    if (this.state.filter) {
      url = `${url}&imei=${imei}&status=${status}&service_id=${service_id}`
    }
    await paginate(url, "SET_ORDER_USER")
    this.setState({isLoading: false});
  }

  status = status => {
    let res = {text: "Pending", color: "info"}
    if (status === 3) {
      res = {text: "Rejected", color: "danger"}
    } else if (status === 4){
      res = {text: "Completed", color: "success"}
    }
    return res;
  }

  formatDate = (created, updated) => {
    let created_at = moment(created);
    let updated_at = moment(updated);

    let diff = updated_at.diff(created_at, 'minutes');
    let response = `${diff} minutes`
    return response;
  }

  render() {
    const {order_user} = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" flex>
                  Orders
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr className={'text-center'}>
                    <th>
                      <Input
                        onChange={this.onChange}
                        type="select"
                        name="status"
                        id="select">
                        <option selected value={""}>TODOS</option>
                        <option value={"1"}>Pending</option>
                        <option value={"3"}>Rejected</option>
                        <option value={"4"}>Success</option>
                      </Input>
                    </th>
                    <th>
                      <Input
                        onChange={this.onChange}
                        type="select"
                        name="service_id"
                        id="select">
                        <option selected value={""}>TODOS</option>
                        {
                          this.props.services_user.map(service => {
                            return (
                              <option value={service.service_id}>{service.service.name}</option>
                            )
                          })
                        }
                      </Input>
                    </th>
                    <th>Price</th>
                    <th>Created</th>
                    <th>
                      <Input
                        className="col-md-12"
                        name={'imei'}
                        value={this.state.imei}
                        onChange={this.onChange}
                        placeholder={'IMEI'}
                      />
                    </th>
                    <th>Code</th>
                    <th>Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    order_user.data.map(order => {
                      const status = this.status(order.status)
                      return (
                        <tr className="text-center" key={`order-history-${order.id}`}>
                          <td className="col-md-2">
                            <Badge
                              color={status.color}
                            >
                              {status.text}
                            </Badge>
                          </td>
                          <td className="col-md-4">{order.service.name}</td>
                          <td className="col-md-1">${order.price}</td>
                          <td className="col-md-2">{moment(order.created_at).format('MMMM Do YYYY')}</td>

                          <td className="col-md-3">{ReactHtmlParser(order.imei)}</td>

                          <td className="col-md-2">{order.code}</td>
                          <td className="col-md-2">{this.formatDate(order.created_at, order.updated_at)}</td>                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  order_user.links.map(link => {
                    return (
                      <Button
                        size={'sm'}
                        disabled={!link.url}
                        className={'mx-1 btn-round'}
                        color={link.active ? 'secondary' : 'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    order_user: state.order_user.order_user,
    services_user: state.services_user.services_user
  }
}

export default connect(mapStateToProps) (OrderUserIndex);