import React from "react";

// reactstrap components
import {Card, CardBody, CardFooter, Row, Col, Form, FormGroup, Input, Button,} from "reactstrap";
import {connect} from "react-redux";
import LoginFunctions from "./functions/LoginFunctions";
import axios from "axios";
import {api} from "../../functions/Base";
import cookie from "js-cookie";
import store from "../../store";

class UserProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.user.name,
      email: props.user.email,
      password: "",
      messageEmailSuccess: "",
      messagePasswordSuccess: "",
      messagePasswordError: "",
      messageEmailError: "",
    }
  }

  componentDidMount() {
    new LoginFunctions().getProfile((error) => this.error(error))
  }

  onChangeHandler = e => {
    e.preventDefault();
    this.setState({[e.target.name]: e.target.value})
  }

  error = (error) => {
    console.log(error);
  }

  onSubmitEmail = () => {
    const {email, name} = this.state;
    const data = {email, name};
    axios.post(`${api}/user_change_email`, data, {
      headers:{
        Authorization: `Bearer ${cookie.get("token")}`,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(res => {
      console.log(res.data.data);
      this.setState({messageEmailSuccess: "Email Changed successfully"})
      store.dispatch({
        type: "SET_LOGIN",
        payload: res.data.data
      });
    }).catch(err => {
      this.setState({messageEmailError: "Something was wrong"})
      //console.log(err.response);
    })
  }

  onSubmitPassword = () => {
    const {password} = this.state;
    const data = {password};
    axios.post(`${api}/user_change_password`, data, {
      headers:{
        Authorization: `Bearer ${cookie.get("token")}`,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(res => {
      console.log(res.data.data);
      this.setState({messagePasswordSuccess: "Password Changed successfully"})
    }).catch(err => {
      this.setState({messagePasswordError: "Something was wrong"})
      //console.log(err.response);
    })

  }

  render() {
    const {user} = this.props;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <div className="image">
                  <img
                    alt="..."
                    src={require("assets/img/bg/david-marcu.jpg").default}
                  />
                </div>
                <CardBody>
                  <div className={'text-center mb-5 text-capitalize'}>
                    <div>
                      <h5 className="title">{user.name}</h5>
                    </div>
                    <p className="description h4 text-center">
                      CREDITS: {user.credits} <br/>
                    </p>
                  </div>
                  <hr />
                  <div className={'text-center text-capitalize mb-2'}>
                    API DHRU CREDENTIALS
                  </div>

                  <p className="list-group-item-dark text-center">
                    APIKEY: {user.api_key} <br/>
                  </p>
                  <p className="list-group-item-dark text-center">
                    URL: https://fenox.fenoxcorp.com <br/>
                  </p>
                  <p className="list-group-item-dark text-center">
                    USERNAME: {user.name} <br/>
                  </p>
                </CardBody>
                <CardFooter>
                  <hr/>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="card-user">
                <CardBody>
                  <Form action="#" method="#">
                    <h6 className={"text-success"}>{this.state.messageEmailSuccess}</h6>
                    <h6 className={"text-danger"}>{this.state.messageEmailError}</h6>
                    <label>Name</label>
                    <FormGroup>
                      <Input onChange={this.onChangeHandler} type="text" name={'name'} value={this.state.name} />
                    </FormGroup>
                    <label>Email</label>
                    <FormGroup>
                      <Input onChange={this.onChangeHandler} type="email" name={'email'} value={this.state.email} />
                    </FormGroup>
                    <Button
                        color={'primary'}
                        className={'rounded'}
                        size={'sm'}
                        block
                        onClick={() => this.onSubmitEmail()}
                    >
                      Change Email
                    </Button>
                  </Form>
                </CardBody>
                <CardFooter>

                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="card-user">
                <CardBody>
                  <Form action="#" method="#">
                    <h6 className={"text-success"}>{this.state.messagePasswordSuccess}</h6>
                    <h6 className={"text-danger"}>{this.state.messagePasswordError}</h6>
                    <label>Change Password</label>
                    <FormGroup>
                      <Input onChange={this.onChangeHandler} type="text" name={'password'} value={this.state.password} />
                    </FormGroup>
                    <Button
                        color={'primary'}
                        className={'rounded'}
                        size={'sm'}
                        block
                        onClick={() => this.onSubmitPassword()}
                    >
                      Change Password
                    </Button>
                  </Form>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps) (UserProfile);
