import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import CategoryFunctions from "./CategoryFunctions";
import {connect} from "react-redux";
import CreateCategoryModal from "./CreateCategoryModal";
import UpdateCategoryModal from "./UpdateCategoryModal";
import DeleteCategoryModal from "./DeleteCategoryModal";

class CategoryIndex extends Component {

  constructor(props) {
    super(props);
    this.state={
      categories:props.categories,
      createModal:false,
      updateModal: false,
      deleteModal: false,
      category:[]
    }
  }

  componentDidMount() { this.getCategories()}

  getCategories = () => new CategoryFunctions().get((data) => this.successCallback(data));

  successCallback = (data) => {
    //console.log(data)
  }

  createCategory = () => this.setState({createModal: !this.state.createModal});
  updateCategory = category => this.setState({updateModal: !this.state.updateModal, category});
  deleteCategory = category => this.setState({deleteModal: !this.state.deleteModal, category});


  errorCallback = (data) => {
    //console.log(data)
  }

  render() {
    const {categories} = this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Categories</CardTitle>
                <Button onClick={() => this.createCategory()}>Create new Category</Button>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-right">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    categories.data.map(category => {
                      return (
                        <tr key={`category-${category.id}`}>
                          <td>{category.name}</td>
                          <td className="text-right">
                            <Button
                              size={'sm'}
                              color={'info'}
                              onClick={() => this.updateCategory(category)}
                            >
                              Update
                            </Button>
                            <Button
                              size={'sm'}
                              color={'danger'}
                              onClick={() => this.deleteCategory(category)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CreateCategoryModal
                isOpen={this.state.createModal}
                toggle={() => this.createCategory()}
                success={(data) => this.successCallback(data)}
                error={ (error) => this.errorCallback(error)}
              />
              <UpdateCategoryModal
                isOpen={this.state.updateModal}
                toggle={() => this.updateCategory()}
                success={(data) => this.successCallback(data)}
                error={ (error) => this.errorCallback(error)}
                category={this.state.category}
              />
              <DeleteCategoryModal
                isOpen={this.state.deleteModal}
                toggle={() => this.deleteCategory()}
                success={(data) => this.successCallback(data)}
                error={ (error) => this.errorCallback(error)}
                category={this.state.category}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  }
}

export default connect(mapStateToProps) (CategoryIndex);