import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import DiegoFunctions from "./DiegoFunctions";
import RejectModal from "../admin/orders/RejectModal";
import Loading from "../../components/Loading";
import ResponseModal from "../admin/orders/ResponseModal";

class OrderIndex extends Component {

    constructor(props) {
        super(props);
        this.state={
            orders:props.orders,
            code:'',
            rejectModal:false,
            responseModal:false,
            order:[],
            isLoading: false,

        }
    }

    componentDidMount() {
        this.getPendingOrders()
    }

    getPendingOrders = () => {
        new DiegoFunctions().get()
    }

    rejectOrder = (order) => {
        if (!order) {
            order = []
        }
        this.setState({order, rejectModal: !this.state.rejectModal})
    }
    responseOrder = (order) => {
        if (!order) {
            order = []
        }
        this.setState({order, responseModal: !this.state.responseModal})
    }

    sendSuccessOrder = data => {
        this.setState({isLoading: true});
        const datas = {code: data.code, status:4}
        new DiegoFunctions().update(data.order.id, datas, () => this.successResponse())
    }

    sendRejectedOrder = (res) => {
        //console.log(res);
        this.setState({isLoading: true})
        let code = res.response ? res.response : "Not Found";
        const data = {code, status: 3}
        new DiegoFunctions().update(res.id, data, () => this.successResponse())
    }

    successResponse = () => this.setState({isLoading: false, rejectModal: false, responseModal: false})

    render() {
        const {orders} = this.props;
        const {rejectModal, order, responseModal} = this.state

        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    {'Orders '}
                                    <Button size={'sm'} rounded color={'info'} onClick={() => this.getPendingOrders()}>Actualizar</Button>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                    <tr className="text-center">
                                        <th>Service</th>
                                        <th>Imei</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orders.map(order => {
                                            return (
                                                <tr className="text-center" key={`category-${order.id}`}>
                                                    <td>{order.service.name}</td>
                                                    <td
                                                        onClick={() => {navigator.clipboard.writeText(order.imei)}}
                                                    >
                                                        {order.imei}
                                                        <svg className={'ml-2'} xmlns="http://www.w3.org/2000/svg" width={15}  viewBox="0 0 448 512">
                                                            <path fill="currentColor"
                                                                  d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/>
                                                        </svg>
                                                    </td>

                                                    <td>
                                                        <Button
                                                            color={'success'}
                                                            size={'sm'}
                                                            onClick={() => this.responseOrder(order)}
                                                        >
                                                            Response
                                                        </Button>
                                                        <Button
                                                            color={'danger'}
                                                            size={'sm'}
                                                            onClick={() => this.rejectOrder(order)}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Loading isLoading={this.state.isLoading} />
                    <RejectModal
                        isOpen={rejectModal}
                        toggle={() => this.rejectOrder()}
                        order={order }
                        rejected={(data) => this.sendRejectedOrder(data)}
                    />
                    <ResponseModal
                        isOpen={responseModal}
                        toggle={() => this.responseOrder()}
                        order={order}
                        response={(data) => this.sendSuccessOrder(data)}
                    />
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.orders.orders
    }
}

export default connect(mapStateToProps) (OrderIndex);
