import React, {Component} from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table
} from "reactstrap";
import {connect} from "react-redux";
import OrderFunctions from "./OrderFunctions";
import moment from "moment";
import Loading from "../../../components/Loading";
import {label, paginate} from "../../../functions/paginate";
import ReactHtmlParser from 'react-html-parser';
import RejectModal from "./RejectModal";

class OrderHistory extends Component {

  constructor(props) {
    super(props);
    this.state={
      orders:props.orders,
      isLoading:false,
      imei:'',
      rejectModal:false,
      order:[],
      filter: false,
      status:'',
      service_id:'',
      services: props.services_user
    }
  }

  componentDidMount() {
    new OrderFunctions().history()
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.onSubmit();
    })
  }

  onSubmit = () => {
    this.setState({filter: true});
    const {imei, status, service_id} = this.state;
    const data = {imei, status, service_id};
    new OrderFunctions().filter(data);
  }

  status = status => {
    let res = {text: "Pending", color: "info"}
    if (status === 3) {
      res = {text: "Rejected", color: "danger"}
    } else if (status === 4){
      res = {text: "Completed", color: "success"}
    }
    return res;
  }

  paginate = async url => {
    this.setState({isLoading: true});
    const {imei, status, service_id} = this.state;
    if (this.state.filter) {
      url = `${url}&imei=${imei}&status=${status}&service_id=${service_id}`
    }

     await paginate(url, "SET_ORDERS_HISTORY")
    this.setState({isLoading: false});
  }

  formatDate = (created, updated) => {
    let created_at = moment(created);
    let updated_at = moment(updated);

    let diff = updated_at.diff(created_at, 'minutes');
    let response = `${diff} minutes`
    return response;
  }

  responseOrder = (order) => {
    if (!order) {
      order = []
    }
    this.setState({order, responseModal: !this.state.responseModal})
  }

  sendRejectedOrder = (res) => {
    //console.log(res);
    this.setState({isLoading: true})
    let code = res.response ? res.response : "Not Found";
    const data = {code, status: 3, rejected: true}
    new OrderFunctions().update(res.id, data, () => this.successResponse())
  }

  successResponse = () => this.setState({isLoading: false, rejectModal: false, responseModal: false})

  rejectOrder = (order) => {
    if (!order) {
      order = []
    }
    this.setState({order, rejectModal: !this.state.rejectModal})
  }

  render() {
    const {orders} = this.props;
    const {rejectModal, order} = this.state;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Orders
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr className={'text-center'}>
                    <th className="col-md-3">
                      <Input
                        onChange={this.onChange}
                        type="select"
                        name="service_id"
                        id="select">
                        <option selected value={""}>ALL</option>
                        {
                          this.props.services_user.map(service => {
                            return (
                              <option value={service.service_id}>{service.service.name}</option>
                            )
                          })
                        }
                      </Input>
                    </th>
                    <th className="col-md-1">User</th>
                    <th className="col-md-1">
                      <Input
                        className="col-md-12"
                        name={'imei'}
                        value={this.state.imei}
                        onChange={this.onChange}
                        placeholder={'Search...'}
                      />
                    </th>
                    <th className="col-md-3">Code</th>
                    <th className="col-md-3">Price</th>
                    <th className="col-md-1">
                      <Input
                        onChange={this.onChange}
                        type="select"
                        name="status"
                        id="select">
                        <option selected value={""}>ALL</option>
                        <option value={"1"}>Pending</option>
                        <option value={"3"}>Rejected</option>
                        <option value={"4"}>Success</option>
                      </Input>
                    </th>
                    <th className="col-md-1">Date</th>
                    <th className="col-md-1">Time</th>
                    <th className="col-md-1">Reject</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    orders.data.map(order => {
                      const status = this.status(order.status)
                      return (
                        <tr className="text-center" key={`category-${order.id}`}>
                          <td className="col-md-1">{order.service.name}</td>
                          <td className="col-md-1">{order.user.name}</td>
                          <td className="col-md-1">{order.imei}</td>
                          <td className="col-md-3">{order.code ? ReactHtmlParser(order.code) : "No Contestado"}</td>
                          <td className="col-md-3">${order.price}</td>
                          <td className="col-md-1">
                            <Badge
                              color={status.color}
                            >
                              {status.text}
                            </Badge>
                          </td>
                          <td className="col-md-2">{moment(order.created_at).format('MMMM Do YYYY')}</td>
                          <td className="col-md-2">{this.formatDate(order.created_at, order.updated_at)}</td>
                          <td className={'col-md-1'}>
                            <Button
                              color={'danger'}
                              size={'sm'}
                              onClick={() => this.rejectOrder(order)}
                            >
                              Reject
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  orders.links.map(link => {
                    return (
                      <Button
                        key={`link-key-${link.label}`}
                        size={'sm'}
                        disabled={!link.url}
                        className={'mx-1 btn-round'}
                        color={link.active ? 'secondary' : 'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
            </Card>
            <RejectModal
              isOpen={rejectModal}
              toggle={() => this.rejectOrder()}
              order={order }
              rejected={(data) => this.sendRejectedOrder(data)}
            />
            <Loading isLoading={this.state.isLoading} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders.order_history,
    services_user: state.services_user.services_user
  }
}

export default connect(mapStateToProps) (OrderHistory);
