import React from 'react';

import {Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import {Line} from "react-chartjs-2";


let chartColor = "#E4E9F7";
const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

export default function DashboardCharts({ days, title}) {

  const label = () => {
    let label = [];

    days.map((day) => {
      label.push(day.day);
    })
    return label;
  }

  const success = () => {
    let success = [];

    days.map((day) => {
      success.push(day.success);
    });
    return success;
  }


  const chartExample2 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#E4E9F7");
      gradientStroke.addColorStop(1, chartColor);

      let gradientFill = ctx.createLinearGradient(0, 300, 0, 50);
      gradientFill.addColorStop(0, "rgba(256, 256, 256, 0)");
      gradientFill.addColorStop(1, hexToRGB("#E4E9F7", 0.4));
      return {
        labels: label(),

        datasets: [
          {
            label: "Orders Success",
            borderColor: "#FFF",
            pointHoverRadius: 6,
            pointRadius: 6,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            barPercentage: 10,
            tension: 0.4,
            data: success(),
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },

        tooltips: {
          enabled: true,
        },
      },
      scales: {
        y: {
          ticks: {
            color: "#FFF",
            beginAtZero: true,
            maxTicksLimit: 5,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 20,
            color: "#FFF",
          },
        },
      },
    },
  };
  const total = () => {
    let count = 0;
    days.map(day => {
      console.log(day.success)
      count = day.success + count;
    })
    return count;
  }
  return (
    <Card className="bg-dark">
      <CardHeader>
        <Row>
          <Col sm="7">
            <div className="numbers pull-left text-white">{total()} Success Orders</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title text-white">
          {title}
        </h6>
        <Line
          data={chartExample2.data}
          options={chartExample2.options}
          height={380}
          width={828}
        />
      </CardBody>
      <CardFooter>
        <hr />
      </CardFooter>
    </Card>
  );
}