import React, {Component} from 'react';
import BillFunctions from "./BillFunctions";
import {connect} from "react-redux";
import {Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import moment from "moment";
import {label, paginate} from "../../../functions/paginate";

class BillIndex extends Component {

  constructor(props) {
    super(props);
    this.state={
      bills:props.bills
    }
  }

  componentDidMount() {
    new BillFunctions().get();
  }

  paginate = async url => {
    this.setState({isLoading: true});
    await paginate(url, "SET_BILLS")
    this.setState({isLoading: false});
  }

  render() {
    const {bills} = this.props
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Bills</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>User</th>
                    <th>Amount</th>
                    <th>State</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    bills.data.map(bill => {
                      return (
                        <tr key={`category-${bill.id}`}>
                          <td>{bill.id}</td>
                          <td>{bill.user.email}</td>
                          <td>{bill.amount}</td>
                          <td>
                            <Badge
                            color={bill.status ? "success" : "danger"}
                            rounded
                            >
                              {bill.status ? "Success" : "Canceled"}
                            </Badge>
                          </td>
                          <td>{moment(bill.created_at).locale('es').format('MMMM Do YYYY, h:mm:ss a')}</td>
                        </tr>
                      )

                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  bills.links.map(link => {
                    return (
                      <Button
                        size={'sm'}
                        disabled={!link.url}
                        className={'mx-1 btn-round'}
                        color={link.active ? 'secondary' : 'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bills: state.bills.bills
  }
}

export default connect(mapStateToProps) (BillIndex);