
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AuthRoute from "./components/AuthRoute";
import GuestRoute from "./components/GuestRoute";
import {Provider} from "react-redux";
import store from "./store";
import {facebookAppId, facebookPageId} from "./variables/general";

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <GuestRoute
                    path="/auth"
                    component={props => <AuthLayout {...props} />}
                />
                <AuthRoute
                    path="/admin"
                    component={props => <AdminLayout {...props} />}
                />
                <Redirect to="/admin/dashboard" />
            </Switch>
        </BrowserRouter>
         <MessengerCustomerChat
          pageId={facebookPageId}
          appId={facebookAppId}
          language={"es_MX"}
        />
    </Provider>,
  document.getElementById("root")
);
