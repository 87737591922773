import Base from "../../../functions/Base";
import store from "../../../store";
export default class BillUserFunctions extends Base {
  get(){
    this.instance.get('/bills').then(res => {
      store.dispatch({
        type: "SET_BILL_USER",
        payload: res.data.data
      });
    })
  }
}